import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import FTPTable from 'components/Web_User_Interface/720p_Series/Alarm/FTP/ftpTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Alarm Menu // Alarm FTP Upload",
  "path": "/Web_User_Interface/720p_Series/Alarm/FTP/",
  "dateChanged": "2017-12-08",
  "author": "Mike Polinowski",
  "excerpt": "To use the video FTP Upload, you first have to set your ftp server address and login credentials. E.g. you can use your routers FTP service as FTP Server - in this case simply put in your routers IP as the FTP Server address. If you set up a FTP server on a local PC (for example with Filezilla Server), use the PC’s local IP address. Of course you can use webservers URL’s as well. The standard FTP port is 21.",
  "image": "./WebUI_720p_SearchThumb_Alarm_FTP.png",
  "social": "/images/Search/WebUI_720p_SearchThumb_Alarm_FTP.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "720p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Alarm Menu // Alarm FTP Upload' dateChanged='2017-12-08' author='Mike Polinowski' tag='INSTAR IP Camera' description='To use the video FTP Upload, you first have to set your ftp server address and login credentials. E.g. you can use your routers FTP service as FTP Server - in this case simply put in your routers IP as the FTP Server address. If you set up a FTP server on a local PC (for example with Filezilla Server), use the PC’s local IP address. Of course you can use webservers URL’s as well. The standard FTP port is 21.' image='/images/Search/WebUI_720p_SearchThumb_Alarm_FTP.png' twitter='/images/Search/WebUI_720p_SearchThumb_Alarm_FTP.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/720p_Serie/Alarm/FTP/' locationFR='/fr/Web_User_Interface/720p_Series/Alarm/FTP/' crumbLabel="FTP Upload" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "720p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#720p-web-user-interface",
        "aria-label": "720p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`720p Web User Interface`}</h1>
    <h2 {...{
      "id": "alarm-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarm-menu",
        "aria-label": "alarm menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Menu`}</h2>
    <h3 {...{
      "id": "alarm-ftp-upload",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#alarm-ftp-upload",
        "aria-label": "alarm ftp upload permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm FTP Upload`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <blockquote>
      <p parentName="blockquote">{`To use the video FTP Upload, you first have to set your ftp server address and login credentials. E.g. you can use your routers FTP service as FTP Server - in this case simply put in your routers IP as the FTP Server address. If you set up a FTP server on a local PC (for example with Filezilla Server), use the PC’s local IP address. Of course you can use webservers URL’s as well. The standard FTP port is 21.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "716px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ed1559c38dcd312085abb59f2dd380c1/6bbf7/WebUI-Alarm_FTP.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAAsSAAALEgHS3X78AAADDElEQVQ4y5VVWU8aURjlRzXt/2jr/zDVv2CffbAaQeNSFNyCxqWyiMUKKOPKIjMDszDMICAjqImJT6f5vjjUKVrrw8nNvblz7vm2M5719XV8/PwZ795/wKe+Pvh8PkxMTGBmZgY+rw/j4+Pw+SYwPT2NyclJXkdHRxm09/v9CAaDfM/r9cKzsbGBkZFvGBr6Cq/Xh6OjI5yeniKbzSKXy/GazeaQz+d5Tzg/P8fZ2RlkWcbq6ioGBgawu7uLeDwOTyAQwNzcHFZWVvjAMAxIkoSTkxPIpTLu7u7QbrfR6XR6cHt7C9M0MTY21hXiodCGh4fx3e9HJBKBqqool8vI5/KoVCpoNBovol6vo9lsIhwOI5PJQBAEeObn5zlvoVAI0WiUSUilpmr8+tXVFa6vr2Hb9rMgYqoDEZJKJpyamuJcRIhQ11nl5WWdwyKyf4EUughnHqtGVQqFVmEYFei6zkoddW8iXF5exsLCApLJFPb29piMLhHZzc3Nq+ghtEwTVaMKu2WjZduwLAuKokCWJA6dEv8aqPX+EFoWZElG+7rN7UF7VVGgaRpardbbFTbqdVimhYeHB9zf33NlK3oFJVnm8G375QoTegiXFpfwpb8fg4ODWFtbY1XUNrqmQVFU1Gq1biNTBE9BZz2ESrmM5H4ShXyB1V1e1lAqlSCJEorFIo+crun80HOgPnTlcGdnB7FYlGfx8DAD06xyMWS5hEazyUknlf9NuP1jG+FwBFtbWzx6lmWiVJKhqOpj4jvdBv87XGqtarXqJqRQRVHCxcUFjo+PUaO2KZdRLIoQiyJ0veIicOCoo65wEabTaVbnDDjlUcgI2P+1z82e+JmAIBxBFMUukbNSOmiiXIQ0HRQqEaZSKSbc3NzEwuIi2xrNejAQ5EefqzKtrioLQoZdhrwwkxE4hKphQNU07kP90SwI9BgVyAHd7SkKqYrFYkx4cHDAHxUKBXZkcmYHu/E4Uul0160JZMLknS6FVIhEIoFkMsmOSzZGdkbGS/8PB7Ozsww6fwq6S6khcjLY3yVcqnN8TyknAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ed1559c38dcd312085abb59f2dd380c1/e4706/WebUI-Alarm_FTP.avif 230w", "/en/static/ed1559c38dcd312085abb59f2dd380c1/d1af7/WebUI-Alarm_FTP.avif 460w", "/en/static/ed1559c38dcd312085abb59f2dd380c1/04ac8/WebUI-Alarm_FTP.avif 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ed1559c38dcd312085abb59f2dd380c1/a0b58/WebUI-Alarm_FTP.webp 230w", "/en/static/ed1559c38dcd312085abb59f2dd380c1/bc10c/WebUI-Alarm_FTP.webp 460w", "/en/static/ed1559c38dcd312085abb59f2dd380c1/d8378/WebUI-Alarm_FTP.webp 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ed1559c38dcd312085abb59f2dd380c1/81c8e/WebUI-Alarm_FTP.png 230w", "/en/static/ed1559c38dcd312085abb59f2dd380c1/08a84/WebUI-Alarm_FTP.png 460w", "/en/static/ed1559c38dcd312085abb59f2dd380c1/6bbf7/WebUI-Alarm_FTP.png 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ed1559c38dcd312085abb59f2dd380c1/6bbf7/WebUI-Alarm_FTP.png",
              "alt": "Web User Interface - 720p Series - Alarm FTP",
              "title": "Web User Interface - 720p Series - Alarm FTP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <FTPTable mdxType="FTPTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "ftp-server-upload",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ftp-server-upload",
        "aria-label": "ftp server upload permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`FTP Server Upload`}</h3>
    <p>{`To use the video `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Alarm/FTP/"
      }}>{`FTP Upload`}</a>{`, you first have to set your ftp server address and login credentials. E.g. you can use your routers FTP service as FTP Server - in this case simply put in your routers IP as the FTP Server address. If you set up a FTP server on a local PC (for example with Filezilla Server), use the PC’s local IP address. Of course you can use webservers URL’s as well. The standard `}<strong parentName="p">{`FTP port is 21`}</strong>{`.`}</p>
    <p>{`You can define an upload directory for your camera under FTP Folder. The root folder of the FTP server can be reached by `}<strong parentName="p">{`./`}</strong>{`. The separator for the folders is the forward slash. A subfolder (e.g. INSTAR) can be reached by `}<strong parentName="p">{`./INSTAR`}</strong>{`. An ending forward slash is not obligatory, but can be used.`}</p>
    <p>{`The FTP Username and FTP Password are the login credentials for your FTP Account.`}</p>
    <p>{`The name of the video file is fixed and corresponds to the following syntax: `}<em parentName="p">{`rec011`}{`_`}{`YYYYMMDDhhmmss.asf`}</em>{`. The first part shows the channel that was used for the recording - 11, 12 or 13. Separated by an underscore follows a timestamp, which marks the time of the motion detection.`}</p>
    <h3 {...{
      "id": "passive-or-port",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#passive-or-port",
        "aria-label": "passive or port permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Passive or Port`}</h3>
    <p>{`For the FTP Mode you can choose between a passive (`}<strong parentName="p">{`PASV`}</strong>{`) and an active mode (`}<strong parentName="p">{`PORT`}</strong>{`). The standard is the active PASV mode. If your server is installed behind a router with an active network address translation (NAT), or if a firewall is blocking the servers network from an external access by your camera, you should choose the PORT mode.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      